<template>
    <NuxtLinkLocale :to="`/stores/${props.slug}`" class="store-container">
        <div class="img-container">
            <div class="mark-container">
                <img width="14px" height="18px" src="assets/images/svg/mark.svg">
            </div>
            <NuxtImgC class="top-img" :src="props.storeImgId" :fallback="fallbackImg" sizes="230px" />
        </div>
        <div class="card-contents">
            <span class="title">{{ props.name }}</span>
            <AtomsRating :rating />
            <span class="partner-since">{{ $t('stores.on_gstock_since') }} {{ useDateFormat(props.partnerSince, 'DD.MM.YYYY') }}</span>
            <div class="sales-per-last-month">
                <span class="label">{{ $t('stores.salesLast30Days') }}</span>
                <span class="content">{{ props.salesPerLastMonth ? formatNumberWithSeparators(props.salesPerLastMonth) + ` ${$t('stores.sales')}` : '—' }}</span>
            </div>

          <div
              v-if="false"
              class="false"
            >
              <div class="line"></div>
            </div>

            <div
              v-if="false"
              class="false"
            >
              <div
                class="insuarance-balance"
              >
                <span class="label">{{ $t('stores.insuranceBalance') }}</span>
                <span class="content">{{ props.insuaranceBalance ? formatNumberWithSeparators(Math.round(Number(props.insuaranceBalance) * CurrentCurrencyRate * 100) / 100) + ' ' + (CurrentCurrency?.symbol ? CurrentCurrency?.symbol : CurrentCurrency?.code) : '—' }}</span>
              </div>
            </div>
        </div>
    </NuxtLinkLocale>
</template>

<script lang="ts" setup>
import { formatNumberWithSeparators } from '~/assets/functions';
const currencyStore = useCurrencyStore();
const { CurrentCurrencyRate, CurrentCurrency } = storeToRefs(currencyStore);
interface IProps {
    name: string;
    rating: number;
    partnerSince: string;
    salesPerLastMonth?: number;
    insuaranceBalance?: number;
    storeImgId?: number | null;
    slug?: string;
}
const props = defineProps<IProps>();
const fallbackImg = useAsset('/assets/images/png/default-imgs/store.png')
</script>

<style lang="scss" scoped>
div {
    box-sizing: border-box;
}

.store-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 20px;
    background-color: var(--white);
    box-shadow: 0px 4px 6px 0px #4D180D1A;
    border: 1px solid rgba(218, 71, 39, 0.1);
    transition: all 0.3s ease-in-out;
    color: #283647;

    &:hover {
        cursor: pointer;
        box-shadow: 0px 16px 24px 0px #4d180d94;
    }
}

.img-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    border-radius: 14px;
    background-color: rgb(162, 162, 162);
    overflow: hidden;

    .top-img {
        aspect-ratio: 93 / 71;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.mark-container {
    position: absolute;
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    justify-content: center;
    background-color: #DA4727;
    position: absolute;
    top: 6px;
    left: 6px;
}

.card-contents {
    display: flex;
    flex-direction: column;
    padding: 0 4px;
    flex-grow: 1;
}

.title {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 130%;
    flex-grow: 1;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.rating {
    margin-bottom: 10px;
}

.partner-since {
    margin-bottom: 14px;
    font-size: 13px;
    font-weight: 600;
    line-height: 80%;
    color: #DA4727;
}

.line {
    width: 100%;
    height: 1px;
    background-color: #F2F5F8;
    margin-top: 7px;
    margin-bottom: 9px;
}

.sales-per-last-month,
.insuarance-balance {
    display: flex;
    flex-direction: column;
    gap: 7px;

    .label {
        opacity: 60%;
        font-size: 13px;
        font-weight: 600;
        line-height: 80%;
        color: #283647;
    }

    .content {
        font-size: 18px;
        font-weight: 700;
        line-height: 80%;
    }
}

@media (max-width:1023px) {
    .store-container {
        padding: 7px;
        border-radius: 16px;
    }

    .card-contents {
        padding: 0 3px;
    }

    .img-container {
        margin-bottom: 10px;
        border-radius: 12px;
    }

    .title {
        margin-bottom: 10px;
        font-weight: 700;
    }

    .partner-since {
        margin-bottom: 12px;
        font-size: 12px;
    }

    .line {
        margin-bottom: 7px;
    }

    .sales-per-last-month,
    .insuarance-balance {
        gap: 6px;

        .label {
            font-size: 12px;
        }

        .content {
            font-size: 16px;
        }
    }
}
</style>
